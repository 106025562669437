.footer	{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	color: $white;
	background: url(../img/grass-pattern.svg) repeat-x center bottom;
    background-size: auto 100%;
    padding-top: 80px;

	a {
		color: inherit;
		text-decoration: none;

		transition: all .2s ease-in-out;
		opacity: 1;

		&:hover, &.active {
			opacity: 0.7;
		}
	}

	h2 {
		font-size: 25px;
		margin-bottom: 30px;
	}
	.social-links {
		.social-link {
			display: inline-block;
			width: 30px;
			height: 30px;
			margin: 0 10px;
			transition: .3s ease-in-out;
			&.facebook {
				background: url(../img/fb.svg) no-repeat center;
				background-size: cover;
			}
			&.twitter {
				background: url(../img/tw.svg) no-repeat center;
				background-size: 100%;
			}
			&.youtube {
				background: url(../img/youtube.svg) no-repeat center;
				background-size: 100%;
			}
			&.instagram {
				background: url(../img/instagram-logo.svg) no-repeat center;
				background-size: 100%;
			}
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	.bottom-nav,
	.footer-menu-mobile {
		padding: 0;
		margin: 30px 0;
		li {
			position: relative;
			display: inline-block;
			list-style: none;
			font-size: 22px;
			text-transform: uppercase;
			padding: 5px 30px;
			&:not(:last-child):after {
				display: inline-block;
				content: '\002F';
				position: absolute;
    			right: 0;
			}
		}
	}
	.footer-menu-mobile {
		display: none;
	}
	.copyright {
		border-top: 1px solid rgba(255,255,255,.5);
		padding: 30px 0 20px;
		font-size: 16px;
		text-transform: uppercase;
		color: $white;
		.footer-menu {
			padding: 0;
			li {
				list-style: none;
				display: inline-block;
				border-left: 1px solid $white;
				&:first-child {
					border: 0;
				}
				a {
					padding: 5px 10px;
					color: inherit;
					text-decoration: none;
				}
			}
		}
	}

	.footer-copy {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			padding: 0 10px;
			margin: 0;
            display: block;

			@media screen and (min-width: 768px) {
                display: inline-block;
				border-left: 1px solid #fff;
				&:first-child {
					border: 0;
				}
			}
		}
	}
}
/*.not-logged-in,*/ .not-found {
	.footer {
		background: #F5F3EE;
		color: #8A7C59;
		padding-top: 60px;
		.social-links {
			.social-link {
				&.facebook {
					background: url(../img/fb-dark.svg) no-repeat center;
					background-size: cover;
				}
				&.twitter {
					background: url(../img/tw-dark.svg) no-repeat center;
					background-size: 100%;
				}
				&.youtube {
					background: url(../img/youtube-dark.svg) no-repeat center;
					background-size: 100%;
				}
				&.instagram {
					background: url(../img/instagram-logo-dark.svg) no-repeat center;
					background-size: 100%;
				}
			}
		}
		.copyright {
			border-top: 1px solid rgba(138,124,89,.5);
			color: #8A7C59;
			.footer-menu {
				li {
					border-left: 1px solid rgba(138,124,89,.5);
					&:first-child {
						border: 0;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.footer	{
		position: relative;
		text-align: center;

		.social-links {
			.social-link {
				display: inline-block;
				width: 25px;
				height: 25px;
			}
		}
		.bottom-nav,
		.footer-menu-mobile {
			margin: 30px 0 0;
			li {
				display: block;
				font-size: 22px;
				&:not(:last-child):after {
					display: none;
				}
			}
		}
		.footer-menu-mobile {
			display: block;
			margin: 0 0 30px;
		}
		.copyright {
			.footer-menu {
				display: none;
			}
		}
	}
}