/**
 *
 * All animations must live in their own file
 * in the animations directory and be included
 * here.
 *
 */

/**
 * Styles shared by multiple animations
 */
@import '../../../node_modules/loaders.css/src/variables';
@import '../../../node_modules/loaders.css/src/mixins';

/**
 * Dots
 */
// @import '../../../node_modules/loaders.css/src/animations/ball-pulse';
// @import '../../../node_modules/loaders.css/src/animations/ball-pulse-sync';
// @import '../../../node_modules/loaders.css/src/animations/ball-scale';
// @import '../../../node_modules/loaders.css/src/animations/ball-scale-random';
// @import '../../../node_modules/loaders.css/src/animations/ball-rotate';
// @import '../../../node_modules/loaders.css/src/animations/ball-clip-rotate';
// @import '../../../node_modules/loaders.css/src/animations/ball-clip-rotate-pulse';
// @import '../../../node_modules/loaders.css/src/animations/ball-clip-rotate-multiple';
// @import '../../../node_modules/loaders.css/src/animations/ball-scale-ripple';
@import '../../../node_modules/loaders.css/src/animations/ball-scale-ripple-multiple';
// @import '../../../node_modules/loaders.css/src/animations/ball-beat';
// @import '../../../node_modules/loaders.css/src/animations/ball-scale-multiple';
// @import '../../../node_modules/loaders.css/src/animations/ball-triangle-trace';
// @import '../../../node_modules/loaders.css/src/animations/ball-pulse-rise';
// @import '../../../node_modules/loaders.css/src/animations/ball-grid-beat';
// @import '../../../node_modules/loaders.css/src/animations/ball-grid-pulse';
// @import '../../../node_modules/loaders.css/src/animations/ball-spin-fade-loader';
// @import '../../../node_modules/loaders.css/src/animations/ball-spin-loader';
// @import '../../../node_modules/loaders.css/src/animations/ball-zig-zag';
// @import '../../../node_modules/loaders.css/src/animations/ball-zig-zag-deflect';

/**
 * Lines
 */
// @import '../../../node_modules/loaders.css/src/animations/line-scale';
// @import '../../../node_modules/loaders.css/src/animations/line-scale-random';
// @import '../../../node_modules/loaders.css/src/animations/line-scale-pulse-out';
// @import '../../../node_modules/loaders.css/src/animations/line-scale-pulse-out-rapid';
// @import '../../../node_modules/loaders.css/src/animations/line-spin-fade-loader';

/**
 * Misc
 */
// @import '../../../node_modules/loaders.css/src/animations/triangle-skew-spin';
// @import '../../../node_modules/loaders.css/src/animations/square-spin';
// @import '../../../node_modules/loaders.css/src/animations/pacman';
// @import '../../../node_modules/loaders.css/src/animations/cube-transition';
// @import '../../../node_modules/loaders.css/src/animations/semi-circle-spin';
