/**
 *
 * All animations must live in their own file
 * in the animations directory and be included
 * here.
 *
 */
/**
 * Styles shared by multiple animations
 */
/**
 * Dots
 */
@keyframes ball-scale-ripple-multiple {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0.0;
  }
}

.ball-scale-ripple-multiple {
  position: relative;
  transform: translateY(-25px);
}

.ball-scale-ripple-multiple > div:nth-child(0) {
  animation-delay: -0.8s;
}

.ball-scale-ripple-multiple > div:nth-child(1) {
  animation-delay: -0.6s;
}

.ball-scale-ripple-multiple > div:nth-child(2) {
  animation-delay: -0.4s;
}

.ball-scale-ripple-multiple > div:nth-child(3) {
  animation-delay: -0.2s;
}

.ball-scale-ripple-multiple > div {
  animation-fill-mode: both;
  position: absolute;
  top: -2px;
  left: -26px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid #fff;
  animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}

/**
 * Lines
 */
/**
 * Misc
 */
