
/** function circular-progress
 **------------------------------------------*/
@function circular-progress($maxPercent, $percent, $color-bar, $color-back) {

  $increment: 360 / $maxPercent;
  $half: round($maxPercent / 2);
  $background-image: '';

  @if $percent < $half {
    $nextdeg: 90deg +  ($increment * $percent);

    $background-image: 'linear-gradient(90deg, #{$color-back} 50%, transparent 50%, transparent),
    linear-gradient(#{$nextdeg}, #{$color-bar} 50%, #{$color-back} 50%, #{$color-back})';

  } @else {
      $nextdeg: -90deg + ($increment * ($percent - $half));

      $background-image: 'linear-gradient(#{$nextdeg}, #{$color-bar} 50%, transparent 50%, transparent),
      linear-gradient(270deg, #{$color-bar} 50%, #{$color-back} 50%, #{$color-back})';
    }
  @return unquote($background-image);
}
@mixin progress-steps {
  @for $i from 1 through 100 {
      .p-#{$i} { background: circular-progress(100, $i, $color-yellow, #fffbe4);}
  }
}
.play-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../img/bg.svg) no-repeat;
    background-size: cover;
    padding: 35px;
    text-align: center;
    overflow: hidden;
    transition: .3s ease-in-out;
    &.fade-in {
        animation: fade-in .3s linear;
        opacity: 1;
        z-index: 9999;
    }
    .close-btn {
        position: absolute;
        top: 55px;
        left: 55px;
        width: 50px;
        height: 50px;
        background: url(../img/back-btn.svg) no-repeat center;
        background-size: 100%;
        border: 0;
        outline: 0;
        z-index: 9000;
    }
    h2 {
        margin-bottom: 10px;
    }
    .meditation-time {
        font-size: 25px;
        span {
            font-size: 32px;
        }
    }

    .meditation-title {
        display: flex;
        align-items: center;
        justify-content: center;

        .arrow-left {
            margin-right: 20px;
        }
        .arrow-right {
            margin-left: 20px;
        }

        .arrow-left, .arrow-right {
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    .current-set {
        position: relative;
        box-sizing: border-box;
        width: 310px;
        height: 310px;
        padding: 10px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        background: #fffbe4;
        border-radius: 100%;
        margin: 50px auto 30px;

        .inner {
            position: absolute;
            top:25px;
            right: 25px;
            left: 25px;
            bottom: 25px;
            background: #f9efb2;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .play-btn, .pause-btn {
                background: none;
                border: 0;
                outline: none;
                width: 95px;
                height: 95px;
                background: url(../img/play-btn-white.svg) no-repeat center;
            }

            .pause-btn {
                background-image: url(../img/pause-btn.svg);
            }
        }
        
        &:before {
            display: block;
            content: '';
            width: 190px;
            height: 115px;
            background: url(../img/blurred-cloud.svg) no-repeat center;
            background-size: cover;
            position: absolute;
            left: 80%;
            bottom: 20px;
            z-index: -1;
            transition: .3s ease;
            animation: swing 4s ease-in-out infinite;
        }
        &-group {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 155px;
            height: 95px;
            background: url(../img/cloud2.svg) no-repeat center;
            background-size: cover;
            position: absolute;
            right: 80%;
            bottom: 20px;
            transition: .3s ease;
            animation: swing 4s ease-in-out 2s infinite;
            p {
                margin: 0;
                font-size: 20px;
                span {
                    font-size: 26px;
                }
            }
        }
    }

    @include progress-steps;

    .duration {
        font-size: 28px;
    }
}