.meditation-calendar {
	table {
		width: 100%;
		max-width: 640px;
		table-layout: fixed;
		text-align: center;
		thead {
			font-family: 'IntroHeadRBase', script;
			font-size: 22px;
		}
		tbody {
			font-size: 25px;
			td {
				background: url(../img/cloud2.svg) no-repeat center;
				background-size: 95%;
				padding: 10px;
				color: $calendar-days;
				&.meditation {
					background: url(../img/meditation-done.svg) no-repeat center;
					background-size: 100%;
				}
				&.no-bg {
					background: none;
				}
			}
		}
	}

	.calendar-navigation {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		.calendar-title {
			width: 100px;

			h3, h4 {
				margin: 0;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.meditation-calendar {
		padding: 30px 0;
		table {
			thead,
			tbody {
				font-size: 20px;
			}
		}
	}
}


.statistics {
	padding: 50px 0 20px;
	&-item {
		display: inline-block;
		margin-right: 200px;
		&:last-child {
			margin-right: 0;
		}
		.number-field {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 145px;
			height: 145px;
			background: $white;
			border-radius: 100%;
			box-shadow: 0 10px 25px 0 rgba(0,0,0,.1);
			color: #7AB0CE;
			margin: 0 auto;
			margin-bottom: 20px;
			.number {
				font-size: 75px;
				line-height: 65px;
			}
			p {
				text-transform: uppercase;
				font-size: 22px;
				line-height: 30px;
				margin-bottom: 0;
			}
		}
		h4 {
			font-size: 23px;
    		font-family: 'IntroHeadRBase', sans-serif;
			text-align: center;
		}
	}
}

@media screen and (max-width: 767px) {
	.statistics {
		display: flex;
		justify-content: center;
		&-item {
			margin: 20px;
		}
	}
}
