$text-color: #3D3D3D;
$text-accent: #39AF52;
$link-active: #CE4040;
$white: #fff;

$calendar-days: #b0b0b0;
$color-yellow: #FCED9A;
$color-pink: #FF807F;
$color-violet: #9AA2FC;
$color-green: #39AF52;
$color-pink: #AC4F37;