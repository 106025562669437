body.not-found {
  background: url(../img/raining-clouds.svg) top center no-repeat, linear-gradient(#bcbcbc, #f7f7f7);
  background-size: cover;

  .main-wrapper {
    padding-bottom: 0;
    height: 100%;
    overflow: hidden;
  }
}

.not-found-container {
  color: #7e7e7e;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
}

.not-found-cloud {
  background: url(../img/cloud-rain.svg) center no-repeat;
  width: 100%;
  background-size: auto 100%;
  height: 300px;
  font-size: 70px;
  line-height: 200px;
}

@media screen and (min-width: 767px) {
  .not-found-cloud {
    background-size: auto;
    height: 490px;
    line-height: 340px;
    font-size: 130px;
  }
}