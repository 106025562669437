@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : #000 !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  3dxy,
  3dxy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  arrowturn,
  arrowturn-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  minus,
  slider,
  slider-r,
  spin,
  spin-r,
  spring,
  spring-r,
  stand,
  stand-r,
  squeeze,
  vortex,
  vortex-r
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "../lib/css-hamburgers/_sass/hamburgers/base";

// Hamburger types
// ==================================================
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/3dx";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/3dx-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/3dy";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/3dy-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/3dxy";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/3dxy-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/arrow";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/arrow-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/arrowalt";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/arrowalt-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/arrowturn";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/arrowturn-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/boring";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/collapse";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/collapse-r";
@import "../lib/css-hamburgers/_sass/hamburgers/types/elastic";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/elastic-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/emphatic";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/emphatic-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/minus";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/slider";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/slider-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/spin";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/spin-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/spring";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/spring-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/stand";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/stand-r";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/squeeze";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/vortex";
// @import "../assets/lib/css-hamburgers/_sass/hamburgers/typestypes/vortex-r";

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
