.profile-form {
	padding: 50px 0;
	transition: .3s ease-in-out;
	&.loading {
		opacity: .3;
	}
	h2 {
		margin-bottom: 40px;
	}
	label {
		font-size: 18px;
		display: block;
		text-transform: uppercase;
	}

	.input-holder {
		margin-top: 40px;
	}

	input:not([type=submit]) {
		display: block;
		width: 80%;
		font-size: 22px;
		font-family: 'IntroHeadRBase', script;
		padding: 5px 10px;
		background: none;
		border: 0;
		outline: 0;
		border-bottom: 1px solid #979797;

		&:disabled {
			color: lighten($text-color, 50);
		}

        &.invite-email {
          width: 100%;
        }
	}
	.msg-error {
		position: relative;
		font-size: 16px;
		color: $color-pink;
	}

	.profile-img {
		margin: 30px 0;
		width: 150px;
		height: 150px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.upload-btn-wrapper {
		position: relative;
		display: inline-block;
		margin-bottom: 40px;
		cursor: pointer;
		input {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer;
		}
	}
	&.login {
		input:not([type=submit]) {
			width: 100%;
		}
	}

	a{
		color: inherit;
		font-size: 20px;
	}
}

.clipboard-copy,
.referral-link {
	margin-top: 20px;
}

.section-separator-text {
	position: relative;
	display: inline-block;
	margin: 60px 0 40px;

	&:after, &:before {
		content: "";
		display: block;
		width: 50px;
		background: $text-color;
		height: 4px;
		position: absolute;
		top: 50%;
		right: 120%;
		transform: translateY(-100%);
	}
	&:after {
		right: auto;
		left: 120%;
	}
}

.login-link {
	margin-left: 15px;
	margin-right: 15px;

	&:last-child {
		margin-right: 0;
	}
}

.cta-text {
	font-size: 22px;
	display: block;
	text-transform: uppercase;
}

.avatar {
	width: 100%;
	height: 100%;
	text-transform: uppercase;

	svg {
		width: 100%;
		height: 100%;
		border-radius: 100%;
	}
}

.referral-link {
  font-weight: bold;
}

@media screen and (max-width: 767px) {
	.profile-form {
		padding: 30px 0;
		label {
			font-size: 22px;
		}
		input:not([type=submit]) {
			font-size: 18px;
			width: 100%;
		}
		.profile-img {
			margin: 10px 0 20px;
		    width: 100px;
		    height: 100px;
		}
	}
}
